import {API, instance} from "../const/api";

export async function DeleteUser(id, Calback) {

    await instance.post(API.Users)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}


export async function GetProvince(Calback) {
    await instance.get(API.Province)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}


export async function UpdateAdmin(formData, Calback) {

    await instance.post(API.Admin, formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}

export async function GetAdmin(type, Calback) {

    await instance.get(API.Admin + "/" + type)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}

export async function DeleteAdmin(formData, Calback) {

    await instance.post(API.Admin, formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}


export async function UpdateUser(formData, Calback) {

    await instance.post(API.user, formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}


export async function GetUser(params, Calback) {

    await instance.get(API.user, {
        params: params
    })
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}

export async function GetUserList(code,params, Calback) {

    await instance.get(API.guest+"/list/"+code, {
        params
    },{
    })
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}



export async function UploadImage(foramData, Calback) {

    await instance.post(API.uploadImage, foramData, {
        headers: {
            'content-type': 'application/json',
        },
    })
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}



export async function MediaRemove(id, Calback) {

    await instance.post(API.mediaremove + "/" + id, {}, {
        headers: {
            'content-type': 'application/json',
        },
    })
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}



export async function Visit( code,Calback) {
    await instance.get(API.visit+"/"+code,{
            headers: {
                'content-type': 'application/json',
            },
        }
    )
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}



export async function UpdateVideoType(formData, Calback) {

    await instance.post(API.videoType, formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}

export async function UpdateVideo(formData, Calback,ProgressEvent) {

    await instance.post(API.video, formData,{
        onUploadProgress: progressEvent => ProgressEvent(progressEvent),
    })
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}


export async function GetVideoType( Calback) {
    await instance.get(API.videoType,{
            headers: {
                'content-type': 'application/json',
            },
        }
    )
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}


export async function Approve(params, Calback) {

    await instance.post(API.Approve, params)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}


export async function GetVideo(params, Calback) {
    await instance.get(API.video,{
        params,
            headers: {
                'content-type': 'application/json',
            },
        }
    )
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}


export async function GetGuest( param,Calback) {
    await instance.get(API.guest,{
            params:param,
            headers: {
                'content-type': 'application/json',
            },
        }
    )
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}


export async function DeleteVideo(id, Calback) {

    await instance.delete(API.video + "/" + id, {}, {
        headers: {
            'content-type': 'application/json',
        },
    })
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}



export async function GetDashboard( param,Calback) {
    await instance.get(API.dashboard,{
            params:param,
            headers: {
                'content-type': 'application/json',
            },
        }
    )
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}

export async function Visits( params,Calback) {
    await instance.get(API.visit,{
        params,
            headers: {
                'content-type': 'application/json',
            },
        }
    )
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}


export async function GetGameScore( param,Calback) {
    await instance.get(API.gameScore,{
            params:param,
            headers: {
                'content-type': 'application/json',
            },
        }
    )
        .then(function (response) {
            Calback(response)
        })
        .catch(function (error) {
            Calback(error.response);
        });
}