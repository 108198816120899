<template>
  <v-container class="custom-container container" >
  <div class="list_form_card" >
    <h1>Dashboard</h1>

    <v-row>
      <v-col class="filter-container" >
        <v-select
            class="select_custom"
            v-model="recruiter"
            :items="recruiter_list"
            item-text="title"
            item-value="value"
            label="ข้อมูลตัวแทน"
            variant="underlined"
        ></v-select>
      </v-col>
      <v-col class="filter-container" >
        <date-range-picker
            v-model="dateRange"
            :auto-apply="true"
            :show-dropdowns="true"
            :ranges="Ranges"
            :locale-data="Lang"
        ></date-range-picker>
        <button @click="ExportData" class="export-btn" >
          <v-icon>mdi-file-excel</v-icon>
          Export
        </button>

      </v-col>
    </v-row>




    <v-row>
      <v-col>
        <DashboardBox
        :data="visit"
        header="จำนวนผู้ที่คลิกผ่าน link"
        des="ของ Recruiter"
        :cb="() => { this.dialogVisit = true }"
        />
      </v-col>
      <v-col>
        <DashboardBox
            :data="visitGuest"
            header="จำนวนผู้ที่คลิกเข้าชม"
            des="ในแต่ละเพจ"
            :cb="() => { this.dialogGuestVisit = true }"
        />
      </v-col>
      <v-col>
        <DashboardBox
            :data="guestView"
            header="จำนวน Guest"
            des="ที่ลงทะเบียน"
            :cb="() => { this.dialogGuest = true }"
        />
      </v-col>

      <v-col>
        <DashboardBox
            :data="guestRegister"
            header="จำนวน Guest"
            des="ที่สมัครเป็นตัวแทน"
            :cb="() => { this.dialogGuestRegister = true }"
        />
      </v-col>
    </v-row>
  </div>

    <div class="text-center">
      <v-bottom-sheet
          v-model="dialogGuest"
          style="height: 90vh;width: 100%"
          inset
      >
        <v-sheet
            class="text-center"
            style="height: 90vh;width: 100%;padding: 1rem"

        >
          <div class="my-3">
            <h1>จำนวน Guest</h1>
            <v-data-table
                :headers="headersGuest"
                :items="resultDataGuest"
                sort-by="calories"
                class="elevation-1"
                :options.sync="optionsGuest"
                sortBy="id"
                :server-items-length="totalItemsGuest"
                :loading="loadingGuest"
                loading-text="กำลังโหลดข้อมูล"
                no-data-text="ไม่มีข้อมูล"
                no-results-text="ไม่มีข้อมูล"
            >
              <template v-slot:top>
              </template>
              <template v-slot:item.users_code="{ item }" >
                <div align="left" >
                  <font v-if="!item.user " >สมัครด้วยตัวเอง</font>
                  <font v-else>{{ item.user.code }}  {{ item.user.full_name }}</font>
                </div>

              </template>

              <template v-slot:item.fullname="{ item }" >
                {{ item.first_name }} {{ item.last_name }}
              </template>
              <template v-slot:item.created_at="{ item }">
                {{  moment(item.created_at).format("DD/MM/yyyy") }}
              </template>
              <template v-slot:item.edutext="{ item }">
                <p v-if="item.is_register == 'true' " >
                  {{ renderEdu(item.about.edu) }}
                </p>
              </template>
              <template v-slot:item.address="{ item }" >
                <p class="mt-2" align="left" v-if="item.is_register == 'true' " >
                  บ้านเลขที่ {{ item.about.address_number }} {{item.about.street}}<br>
                  จังหวัด {{ item.about.provinces.PROVINCE_NAME }}
                  อำเภอ {{ item.about.amphures.AMPHUR_NAME }}<br>
                  ตำบล {{ item.about.districts.DISTRICT_NAME }}
                  รหัสไปรษณีย์ {{ item.about.postcode }}
                </p>
              </template>

              <template v-slot:no-data>
                <v-btn
                    color="primary"
                    @click="GetData()"
                >
                  Reset
                </v-btn>
              </template>

            </v-data-table>
          </div>
        </v-sheet>
      </v-bottom-sheet>

      <v-bottom-sheet
          v-model="dialogGuestRegister"
          style="height: 90vh;width: 100%"
          inset
      >
        <v-sheet
            class="text-center"
            style="height: 90vh;width: 100%;padding: 1rem"

        >
          <div class="my-3">
            <h1>จำนวน Guest ที่สมัครเป็นตัวแทน</h1>
            <v-data-table
                :headers="headersGuestRegister"
                :items="resultDataGuestRegister"
                sort-by="calories"
                class="elevation-1"
                :options.sync="optionsGuestRegister"
                sortBy="id"
                :server-items-length="totalItemsGuestRegister"
                :loading="loadingGuestRegister"
                loading-text="กำลังโหลดข้อมูล"
                no-data-text="ไม่มีข้อมูล"
                no-results-text="ไม่มีข้อมูล"
            >
              <template v-slot:top>
              </template>
              <template v-slot:item.users_code="{ item }" >
                <div align="left" >
                  <font v-if="!item.user " >สมัครด้วยตัวเอง</font>
                  <font v-else>{{ item.user.code }}  {{ item.user.full_name }}</font>
                </div>

              </template>

              <template v-slot:item.fullname="{ item }" >
                {{ item.first_name }} {{ item.last_name }}
              </template>


              <template v-slot:item.created_at="{ item }">
                {{  moment(item.created_at).format("DD/MM/yyyy") }}
              </template>
              <template v-slot:item.edutext="{ item }">
                <p v-if="item.is_register == 'true' " >
                  {{ renderEdu(item.about.edu) }}
                </p>
              </template>
              <template v-slot:item.address="{ item }" >
                <p class="mt-2" align="left" v-if="item.is_register == 'true' " >
                  บ้านเลขที่ {{ item.about.address_number }} {{item.about.street}}<br>
                  จังหวัด {{ item.about.provinces.PROVINCE_NAME }}
                  อำเภอ {{ item.about.amphures.AMPHUR_NAME }}<br>
                  ตำบล {{ item.about.districts.DISTRICT_NAME }}
                  รหัสไปรษณีย์ {{ item.about.postcode }}
                </p>
              </template>

              <template v-slot:no-data>
                <v-btn
                    color="primary"
                    @click="GetData()"
                >
                  Reset
                </v-btn>
              </template>

            </v-data-table>
          </div>
        </v-sheet>
      </v-bottom-sheet>

      <v-bottom-sheet
          v-model="dialogVisit"
          style="height: 90vh;width: 100%"
          inset
      >
        <v-sheet
            class="text-center"
            style="height: 90vh;width: 100%;padding: 1rem"

        >
          <div class="my-3">
            <h1>จำนวนผู้ที่คลิกลิ้ง</h1>
            <v-data-table
                :headers="headersVisit"
                :items="resultDataVisit"
                sort-by="calories"
                class="elevation-1"
                :options.sync="optionsVisit"
                sortBy="id"
                :server-items-length="totalItemsVisit"
                :loading="loadingVisit"
                loading-text="กำลังโหลดข้อมูล"
                no-data-text="ไม่มีข้อมูล"
                no-results-text="ไม่มีข้อมูล"
            >
              <template v-slot:top>
              </template>
              <template v-slot:item.users_code="{ item }" >
                <div align="left" >
                  <font v-if="!item.user " >สมัครด้วยตัวเอง</font>
                  <font v-else>{{ item.user.code }}  {{ item.user.full_name }}</font>
                </div>

              </template>

              <template v-slot:item.fullname="{ item }" >
                {{ item.first_name }} {{ item.last_name }}
              </template>
              <template v-slot:item.created_at="{ item }">
                {{  moment(item.created_at).format("DD/MM/yyyy") }}
              </template>
              <template v-slot:item.edutext="{ item }">
                <p v-if="item.is_register == 'true' " >
                  {{ renderEdu(item.about.edu) }}
                </p>
              </template>
              <template v-slot:item.address="{ item }" >
                <p class="mt-2" align="left" v-if="item.is_register == 'true' " >
                  บ้านเลขที่ {{ item.about.address_number }} {{item.about.street}}<br>
                  จังหวัด {{ item.about.provinces.PROVINCE_NAME }}
                  อำเภอ {{ item.about.amphures.AMPHUR_NAME }}<br>
                  ตำบล {{ item.about.districts.DISTRICT_NAME }}
                  รหัสไปรษณีย์ {{ item.about.postcode }}
                </p>
              </template>

              <template v-slot:no-data>
                <v-btn
                    color="primary"
                    @click="GetData()"
                >
                  Reset
                </v-btn>
              </template>

            </v-data-table>
          </div>
        </v-sheet>
      </v-bottom-sheet>

      <v-bottom-sheet
          v-model="dialogGuestVisit"
          style="height: 90vh;width: 100%"
          inset
      >
        <v-sheet
            class="text-center"
            style="height: 90vh;width: 100%;padding: 1rem"

        >
          <div class="my-3">
            <h1>จำนวนผู้ที่คลิกลิ้ง</h1>
            <v-data-table
                :headers="headersGuestVisit"
                :items="resultDataGuestVisit"
                sort-by="calories"
                class="elevation-1"
                :options.sync="optionsGuestVisit"
                sortBy="id"
                :server-items-length="totalItemsGuestVisit"
                :loading="loadingGuestVisit"
                loading-text="กำลังโหลดข้อมูล"
                no-data-text="ไม่มีข้อมูล"
                no-results-text="ไม่มีข้อมูล"
            >
              <template v-slot:top>
              </template>
              <template v-slot:item.users_code="{ item }" >
                <div align="left" >
                  <font v-if="!item.user " >สมัครด้วยตัวเอง</font>
                  <font v-else>{{ item.user.code }}  {{ item.user.full_name }}</font>
                </div>
              </template>

              <template v-slot:item.guestdata="{ item }" >
                {{ item.guest.full_name }}
              </template>

              <template v-slot:item.fullname="{ item }" >
                {{ item.first_name }} {{ item.last_name }}
              </template>
              <template v-slot:item.created_at="{ item }">
                {{  moment(item.created_at).format("DD/MM/yyyy") }}
              </template>
              <template v-slot:item.edutext="{ item }">
                <p v-if="item.is_register == 'true' " >
                  {{ renderEdu(item.about.edu) }}
                </p>
              </template>
              <template v-slot:item.address="{ item }" >
                <p class="mt-2" align="left" v-if="item.is_register == 'true' " >
                  บ้านเลขที่ {{ item.about.address_number }} {{item.about.street}}<br>
                  จังหวัด {{ item.about.provinces.PROVINCE_NAME }}
                  อำเภอ {{ item.about.amphures.AMPHUR_NAME }}<br>
                  ตำบล {{ item.about.districts.DISTRICT_NAME }}
                  รหัสไปรษณีย์ {{ item.about.postcode }}
                </p>
              </template>

              <template v-slot:no-data>
                <v-btn
                    color="primary"
                    @click="GetData()"
                >
                  Reset
                </v-btn>
              </template>

            </v-data-table>
          </div>
        </v-sheet>
      </v-bottom-sheet>

    </div>


  </v-container>


</template>

<script>
// @ is an alias to /src

import {GetDashboard, GetGuest, GetUser, Visits} from "../lib/controller";
import {ResponseData} from "../lib/utility";
import DashboardBox from "../components/DashBoardBox.vue";
import moment from "moment";
import {baseUrlMain} from "../const/api";

let today = new Date();
today.setHours(0, 0, 0, 0);
today.setDate(today.getDate() + 2);

let yesterday = new Date();
yesterday.setDate(today.getDate() - 1);
yesterday.setHours(0, 0, 0, 0);

let last7 = new Date();
last7.setDate(today.getDate() - 7);
last7.setHours(0, 0, 0, 0);

let last15 = new Date();
last15.setDate(today.getDate() - 15);
last15.setHours(0, 0, 0, 0);

export default {
  name: 'Home',
  computed: {
    moment() {
      return moment
    }
  },
  components: {DashboardBox},
  data: () => ({
    type: 'month',
    types: ['month', 'week', 'day', '4day'],
    mode: 'stack',
    modes: ['stack', 'column'],
    value: '',
    dateShow: '',
    headersVisit: [
      {text: 'เชิญชวนจาก',align:'center',  value:'users_code'},
      {text: 'วันที่เพิ่มข้อมูล',align:'center', value: 'created_at'},
    ],
    headersGuestVisit: [
      {text: 'ชื่อผู้เข้าชม',align:'center',  value:'guestdata'},
      {text: 'เชิญชวนจาก',align:'center',  value:'users_code'},
      {text: 'หน้าเว็บ',align:'center',  value:'route'},
      {text: 'วันที่เพิ่มข้อมูล',align:'center', value: 'created_at'},
    ],
    headersGuest: [
      {text: 'ชื่อ-สกุล', key:'full_name' , value:'full_name'},
      {text: 'เชิญชวนจาก',align:'center',  value:'users_code'},
      {text: 'อึเมล',align:'center', value: 'email'},
      {text: 'เบอรโทร',align:'center', value: 'tel'},
      {text: 'วันที่เพิ่มข้อมูล',align:'center', value: 'created_at'},
    ],
    headersGuestRegister: [
      {text: 'ชื่อ-สกุล', key:'full_name' , value:'full_name'},
      {text: 'เชิญชวนจาก',align:'center',  value:'users_code'},
      {text: 'อึเมล',align:'center', value: 'email'},
      {text: 'เบอรโทร',align:'center', value: 'tel'},
      {text: 'วันเกิด',align:'center', value: 'about.dob'},
      {text: 'วุฒิการศึกษา',align:'center', key:'edu' ,value: 'edutext'},
      {text: 'ที่อยู่',align:'center', key:'provinces', value: 'address'},
      {text: 'วันที่เพิ่มข้อมูล',align:'center', value: 'created_at'},
    ],
    events: [],
    user: [],
    machine: [],
    bookingData: [],
    dialog: true,
    bookingUser: [],
    hour: [],
    hour_sleep: '',
    detail: '',
    minute_sleep: '',
    hour_awake: '',
    valid: true,
    minute_awake: '',
    userRegister: 0,
    recruiter_list: [],
    recruiter:"",
    date_booking: '',
    minute: [],
    machine_id: '',
    user_id: '',
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    attributes: [],
    donate: [],
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
    ],

    dialogGuest:false,
    resultDataGuest:[],
    itemsPerPageGuest: 1,
    loadingGuest: true,
    totalItemsGuest: 0,
    optionsGuest: {},

    dialogGuestRegister:false,
    resultDataGuestRegister:[],
    itemsPerPageGuestRegister: 1,
    loadingGuestRegister: true,
    totalItemsGuestRegister: 0,
    optionsGuestRegister: {},

    dialogVisit:false,
    resultDataVisit:[],
    itemsPerPageVisit: 1,
    loadingVisit: true,
    totalItemsVisit: 0,
    optionsVisit: {},


    dialogGuestVisit:false,
    resultDataGuestVisit:[],
    itemsPerPageGuestVisit: 1,
    loadingGuestVisit: true,
    totalItemsGuestVisit: 0,
    optionsGuestVisit: {},

    visit:0,
    visitGuest:0,
    guestView:0,
    guestRegister:0,

    dateRange: {
      startDate: last7,
      endDate: today,
    },
    dateFormat: "Y-m-d",
    Ranges: {
      '15 วันก่อนหน้า': [last15, today],
      'วันนี้': [today, today],
      'เมื่อวาน': [yesterday, yesterday],
      'ปีนี้': [
        new Date(today.getFullYear(), 0, 1),
        new Date(today.getFullYear(), 11, 31),
      ],
      'เดือนก่อน': [
        new Date(today.getFullYear(), today.getMonth() - 1, 1),
        new Date(today.getFullYear(), today.getMonth(), 0),
      ],
    },
    Lang: {
      direction: "ltr",
      format: "mm-dd-yyyy",
      separator: " - ",
      applyLabel: "ตกลง",
      cancelLabel: "ยกเลิก",
      weekLabel: "W",
      customRangeLabel: "เลือกช่วงเวลา",
      daysOfWeek: ["อา.", "จ.", "อ.", "พ.", "พฤ", "ศ", "ส"],
      monthNames: [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
      firstDay: 1,
    }

  }),
  watch: {
    optionsGuest: {
      handler () {
        this.GetDataGuest()
      },
      deep: true,
    },
    optionsGuestRegister: {
      handler () {
        this.GetDataGuesRegister()
      },
      deep: true,
    },
    optionsVisit: {
      handler () {
        this.GetDataVisit()
      },
      deep: true,
    },
    optionsGuestVisit: {
      handler () {
        this.GetDataGuestVisit()
      },
      deep: true,
    },
    recruiter() {
      this.GetDashboard();
      this.GetDataGuest()
      this.GetDataGuesRegister()
      this.GetDataVisit()
      this.GetDataGuestVisit()
    },
    dateRange(){
      this.GetDashboard();
      this.GetDataGuest()
      this.GetDataGuesRegister()
      this.GetDataVisit()
      this.GetDataGuestVisit()
    },
  },
  methods:{
    ExportData(){

      let params = {}

      if(this.recruiter){
        params.codes = this.recruiter
      }


      if(this.dateRange){
        params.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD")
        params.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD")
      }

      const queryString = new URLSearchParams(params).toString();

      window.open(baseUrlMain+"/export/?"+queryString,"_blank")
    },
    setDate(e){
      this.dateShow = "";
      this.date_booking = "";
      console.log(e)
      if(e.attributes.length != 0 && e.attributes[0].customData.is_full){
        this.$toast.open({
          message: 'ไม่สามารถจองวันนี้ได้',
          type: "warning",
          duration: 10000,
          dismissible: true
        })
      }else{
        this.dateShow = e.ariaLabel;
        this.date_booking = e.id;
      }

    },
    async GetDataGuestVisit(){

      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsGuestVisit

      const params = {
        page,itemsPerPage,sortDesc:sortDesc[0],sortBy:sortBy[0]
      }


      if(this.dateRange){
        params.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD")
        params.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD")
      }


      console.warn(params)
      this.loadingGuestVisit = true;



      console.log(this)
      await Visits(params,(response)=>{
        const {status, data} = ResponseData(response)
        this.loadingGuestVisit = false;
        switch (status){
          case 200:
            this.resultDataGuestVisit = data.data;
            this.totalItemsGuestVisit = data.total;
            this.itemsPerPageGuestVisit = data.to;
            break;
          default:
            break;
        }

      });
    },
    async GetDataVisit(){

      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsVisit

      const params = {
        page,itemsPerPage,sortDesc:sortDesc[0],sortBy:sortBy[0]
      }

      if(this.dateRange){
        params.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD")
        params.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD")
      }

      params.is_main = true;

      console.warn(params)
      this.loadingVisit = true;



      console.log(this)
      await Visits(params,(response)=>{
        const {status, data} = ResponseData(response)
        this.loadingVisit = false;
        switch (status){
          case 200:
            this.resultDataVisit = data.data;
            this.totalItemsVisit = data.total;
            this.itemsPerPageVisit = data.to;
            break;
          default:
            break;
        }

      });
    },
    async GetDataGuest(){

      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsGuest

      const params = {
        page,itemsPerPage,sortDesc:sortDesc[0],sortBy:sortBy[0]
      }
      console.warn(params)
      this.loadingGuest = true;
      params.is_register = 'false';

      if(this.dateRange){
        params.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD")
        params.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD")
      }

      console.log(this)
      await GetGuest(params,(response)=>{
        const {status, data} = ResponseData(response)
        this.loadingGuest = false;
        switch (status){
          case 200:
            this.resultDataGuest = data.data;
            this.totalItemsGuest = data.total;
            this.itemsPerPageGuest = data.to;
            break;
          default:
            break;
        }

      });
    },
    async GetDataGuesRegister(){

      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsGuestRegister

      const params = {
        page,itemsPerPage,sortDesc:sortDesc[0],sortBy:sortBy[0]
      }
      console.warn(params)
      this.loadingGuestRegister = true;
      params.is_register = 'true';

      if(this.dateRange){
        params.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD")
        params.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD")
      }


      console.log(this)
      await GetGuest(params,(response)=>{
        const {status, data} = ResponseData(response)
        this.loadingGuestRegister = false;
        switch (status){
          case 200:
            this.resultDataGuestRegister = data.data;
            this.totalItemsGuestRegister = data.total;
            this.itemsPerPageGuestRegister = data.to;
            break;
          default:
            break;
        }

      });
    },
    validate() {
      console.log(this.file)
      if (this.$refs.form.validate()) {
        this.Booking_();
      }
    },
    async GetDashboard(){


        let params = {};

        if(this.recruiter){
          params.code = this.recruiter
        }

        if(this.dateRange){
          params.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD")
          params.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD")
        }

        console.log(this.recruiter)

        await GetDashboard(params,(response)=>{
          const {status, data} = ResponseData(response)

          switch (status){
            case 200:
              this.visit = data.visit;
              this.visitGuest = data.visitGuest;
              this.guestView = data.guestView;
              this.guestRegister = data.guestRegister;
              break;
            default:
              break;
          }

        });





    },
    async GetData(){

      let params = {};

      params.approve = true;


      await GetUser(params,(response)=>{
        const {status, data} = ResponseData(response)
        let recruiter_list = [];
        switch (status){
          case 200:



            data.data.forEach((result) => {
              recruiter_list.push({
                title: result.full_name,
                value: result.codes,
              })
            })

            this.recruiter_list = recruiter_list;
            break;
          default:
            break;
        }

      });
    },
    renderEdu(edu){
      switch (edu){
        case "1":
          return "ต่ำกว่าปริญญาตรี";
        case "2":
          return "ปริญญาตรี";
        case "3":
          return "ปริญญาตรีขึ้นไป";
        default:
          return "-";

      }

    },
  },
  async mounted() {
    await this.GetData();
    await this.GetDashboard();
    await this.GetDataGuest();
    await this.GetDataVisit();
  }
}
</script>
<style scoped >
.form_table{
  width: 80%;
}
.filter-container{
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}
.export-btn{
  background: #4CAF50;
  margin-left: 1rem;
  color: white;
}
.export-btn i{
  color: white;
}
</style>

