const axios = require('axios').default;
console.log(process.env.NODE_ENV)
console.log(process.env)
const host = (location.host).split(":");
let baseUrl;
let baseUrlMain;
let baseUrlMainLink;
let production  = true;


baseUrl = production == false ? "http://"+host[0]+":8000/api" : "https://fwdapi.commsk.dev/api";
baseUrlMain = production == false ? "http://"+host[0]+":8000" : "https://fwdapi.commsk.dev/" ;
baseUrlMainLink = "https://fwd.commsk.dev" ;



const instance = axios.create({
    baseURL: baseUrl,
    timeout: 10000,
    headers: {
        'X-Custom-Header': 'foobar',
        'content-type': 'application/json',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        'token':localStorage.getItem("token")
    }
});




const API = {
    Auth: '/auth/admin',
    Admin: '/admin',
    updatedonated:'/updatedonated',
    uploadImage: '/uploadImage',
    user: '/user',
    guest: '/guest',
    dashboard: '/dashboard',
    mediaremove: '/mediaremove',
    live: '/live',
    visit: '/visit',
    videoType: '/video_type',
    video: '/video',
    Approve: '/approve',
    gameScore: '/game_score',
}





export {API,instance,baseUrl,baseUrlMainLink,baseUrlMain};
